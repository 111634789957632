@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Montserrat-Regular';
  src: url("assets/fonts/Montserrat-Regular.ttf") format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat-Bold';
  src: url("assets/fonts/Montserrat-Bold.ttf") format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat-SemiBold';
  src: url("assets/fonts/Montserrat-SemiBold.ttf") format('truetype');
  font-display: swap;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@layer components {
  .row {
    @apply flex flex-row
  }

  .col {
    @apply flex flex-col
  }

  table th:first-child{
    @apply rounded-l-md;
  }

  table th:last-child{
    @apply rounded-r-md;
  }

  table {
    @apply border-separate border-spacing-0
  }

  .order-table tr:hover td:first-child {
    @apply border-l border-ash-tree
  }

  .order-table tr:hover td:last-child {
    @apply border-r border-ash-tree
  }

  .order-table tr:hover td {
    @apply border-t border-ash-tree
  }

  .order-table tr:hover td {
    @apply border-b border-ash-tree
  }

  .analytics-table tbody tr:nth-child(odd) {
    @apply bg-doctor
  }

  .analytics-table tbody tr:last-child {
    @apply bg-liberated-lime font-Montserrat-Bold
  }

  .analytics-table th:first-child{
    @apply rounded-none
  }

  .analytics-table th:last-child{
    @apply rounded-none
  }
}
